import React from 'react';
import './textEditor.scss';
import {
	Avatar,
	Box,
	Button,
	Checkbox,
	Grid,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	InputAdornment
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import { URLS } from 'src/helpers/constants/urls';

import { TextField } from '../TextField';
import { renderSwitchForDocumentIcon } from './utils/renderSwitchForDocumentIcon';

import loaderGif from 'src/assets/images/icons/Messages/loaderGif.gif';
import placeholder from 'src/assets/images/icons/Messages/placeholder.png';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/images/icons/errorIcon.svg';
import { ReactComponent as CheckBoxIcon } from 'src/assets/images/icons/checkBox.svg';
import { ReactComponent as CheckedBoxIcon } from 'src/assets/images/icons/checkedBox.svg';
import Editor from './components/Editor';
import EditorHeader from './components/EditorHeader';
import { initialValue } from '../../../helpers/constants/textEditorInitialValue';
import { useState } from 'react';
import { deserialize, serializeText } from './utils/parsing';
import { checkText } from './utils/editor';
import { preparePostListForRequest } from './utils/preparePostListForRequest';
import { setApiMessage } from '../../../helpers/commonFunctions';
import { ThumbnailUpload } from '../ThumbnailUpload/ThumbnailUpload';

export const TextEditor = ({
	token,
	user,
	submit,
	selectedItem,
	postData,
	cancelBtnClick,
	allUsersList,
	initialRequestData,
}) => {
	const { t } = useTranslation();

	const [text, setText] = useState(null);
	const [textError, setTextError] = useState('');
	const [checked, setChecked] = React.useState([]);
	const [deletedItemArray, setDeletedItemArray] = useState([]);
	const [allImages, setAllImages] = useState([]);
	const [imageStatus, setImageStatus] = useState(true);
	const [sStatus, setSponsoredPost] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isPaid, setIsPaid] = useState(Boolean(postData?.isPaid));
	const [thumbnail, setThumbnail] = useState();
	const [amountError, setAmountError] = useState(false);
	const typeList = [
		{ id: 1, name: t('community.sticky_post') },
		{ id: 2, name: t('community.allow_for_comments') },
	];
	
	const [amount, setAmount] = useState(postData?.amount || 5);

	const handleAmountUpdate = (event) => {
		
		setAmount(event.target.value);
	}

	const validateAmount = (event) => {
		event.preventDefault();

		if(event.target.value > 100 || event.target.value < 5) {
			setAmountError(true);
		} else {
			setAmountError(false);
		}
	};

	const checkHandleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const setImageList = (img) => {
		setAllImages(img);
	};

	const deletePostItem = (id) => {
		var deletedImage = allImages && allImages?.filter((item, index) => index !== id);

		setDeletedItemArray(deletedImage);
		setAllImages(deletedImage);
	};

	const handleImageLoaded = () => {
		setImageStatus(false);
	};

	const showItems = (item, index) => {
		switch (item?.type) {
			case 'doc':
				return (
					<>
						<Box className="message-doc-view create-post-item-sec">
							<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
							<Link href={item} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.location)}
							</Link>
						</Box>
					</>
				);
			case 'image':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<Avatar
							src={imageStatus === true ? loaderGif : URLS.mediaURL + item?.location}
							alt="post-image"
							onError={(e) => {
								e.onerror = null;
								e.target.src = placeholder;
							}}
							onLoad={handleImageLoaded}
						/>
					</Box>
				);
			case 'video':
				return (
					<Box width="200px" className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="100%"
							height="100%"
							controls={true}
						/>
					</Box>
				);
			case 'audio':
				return (
					<Box className="create-post-item-sec">
						<CloseIcon className="create-post-close" onClick={() => deletePostItem(index)} />
						<ReactPlayer
							className="react-player audio-player"
							url={URLS.mediaURL + item?.location}
							playing={false}
							width="270px"
							height="30px"
							controls={true}
						/>
					</Box>
				);
		}
	};

	const onSubmit = async (requestData) => {
		if (checkText(text) || Boolean(allImages.length)) {
			setIsLoading(true);
			const rawHTML = checkText(text) ? serializeText(text) : serializeText(initialValue);

			const requestParameter = {
				post: {
					...initialRequestData,
					id: postData?.id,
					sponsored: sStatus ? 'yes' : 'no',
					sticky: checked.includes(1) ? 'yes' : 'no',
					comments: checked.includes(2) ? 'yes' : 'no',
					name: requestData?.title,
					postList: preparePostListForRequest(allImages, rawHTML, thumbnail),
					isPaid: isPaid,
					...(isPaid ? { amount: amount } : {}),
				},
			};

			try {
				await submit(requestParameter, checked, text);
				setChecked([2]);
				setText(initialValue);
				setSponsoredPost(false);
				setAllImages([]);
			} catch (error) {
				setApiMessage('error', error?.response?.data?.message);
			} finally {
				setIsLoading(false);
			}
		} else {
			setTextError(t('validation.required_message'));
		}
	};

	React.useEffect(() => {
		if (postData?.PostLists) {
			const textWithoutParsing = postData.PostLists.find((item) => item.Item.type === 'text');
			const postAllData = postData.PostLists.filter((item) => item?.Item?.type !== 'text' && !item?.Item?.isThumbnail);
			const thumbnail = postData.PostLists.find((item) => item?.Item?.isThumbnail);

			if(thumbnail) {
				setThumbnail({...thumbnail.Item, item_id: thumbnail.item_id});
			}

			const formattedAllData =
				postAllData &&
				postAllData?.map((item) => {
					let splitdata = item?.Item?.data.split('/');

					splitdata = splitdata && splitdata[splitdata.length - 1];

					return {
						id: item?.item_id,
						type: item?.Item?.type,
						location: splitdata,
					};
				});

			setAllImages(formattedAllData);

			const checkedArr = [];

			if (postData?.sticky === 'yes') {
				checkedArr.push(1);
			}

			if (postData?.comments === 'yes') {
				checkedArr.push(2);
			}

			setChecked(checkedArr);

			if (textWithoutParsing) {
				const document = new DOMParser().parseFromString(textWithoutParsing.Item.data, 'text/html');

				setText(deserialize(document.body));
			}
		} else {
			setText(initialValue);
			setChecked([2]);
		}
	}, [postData]);

	return (
		<Box className="text-field-grid post-title-input">
			<EditorHeader user={user} selectedItem={selectedItem} postData={postData} />

			<Box className="text-field-grid post-title-input">
				<Formik
					initialValues={{
						title: postData?.name ? postData.name : '',
						text: '',
					}}
					validationSchema={Yup.object().shape({
						title: Yup.string().required(t('validation.required_message')),
						// text: Yup.string().required(t('validation.required_message'))
					})}
					onSubmit={onSubmit}
				>
					{({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
						<form onSubmit={handleSubmit}>
							{thumbnail && (
								<Box style={{ width: '100%', height: '400px', paddingBottom: '10px' }}>
									<img
										src={thumbnail.data ? thumbnail.data : URLS.mediaURL + thumbnail.location}
										style={{ width: '100%', height: '100%', objectFit: 'cover' }}
										alt="preview-image"
									/>
								</Box>
							)}
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} className="text-field-grid">
									<TextField
										error={Boolean(touched.title && errors.title)}
										fullWidth
										helperText={
											touched.title ? (
												touched.title && (
													<Box disply="flex" justifyContent="center">
														{errors.title}
														<span className="field-character-count">{values.title.length}/150</span>
													</Box>
												)
											) : (
												<span className="field-character-count">{values.title.length}/150</span>
											)
										}
										variant="filled"
										InputProps={{
											maxLength: 500,
											endAdornment: touched.title && errors.title ? <ErrorIcon /> : null,
										}}
										label={t('community.post_title')}
										placeholder={t('community.post_title')}
										className="matrix-textfield"
										size="small"
										name="title"
										onBlur={handleBlur}
										onChange={handleChange}
										inputProps={{ maxLength: 150 }}
										value={values.title}
									/>
								</Grid>
								<Grid item xs={12} md={12} className="text-field-grid cp-cnt">
									<Box className="custome-text-editior">
										{text && (
											<Editor
												allUsersList={allUsersList}
												token={token}
												deletedItemArray={deletedItemArray}
												state={text}
												setState={setText}
												setImageList={setImageList}
											/>
										)}
									</Box>
								</Grid>
								<span className="editior-error-message">{textError}</span>
								<Grid item xs={12} md={12} className="image-wrapper">
									<Box className="image-wrapper-inner">
										{allImages &&
											allImages.map((imageSrc, index) => {
												return showItems(imageSrc, index);
											})}
									</Box>
								</Grid>
								<Grid item xs={12} md={12}>
									<Box className="select-posting-type">
										<Typography className="check-heading">{t('community.admin_options')}</Typography>
										<List>
											{typeList.map((type, index) => {
												const labelId = `checkbox-list-label-${type?.id}`;

												return type?.id === 1 ? (
													user?.isAdmin ? (
														<ListItem key={index} role={undefined} dense button onClick={checkHandleToggle(type?.id)}>
															<ListItemIcon>
																<Checkbox
																	icon={<CheckBoxIcon />}
																	checkedIcon={<CheckedBoxIcon />}
																	edge="start"
																	checked={checked.indexOf(type?.id) !== -1}
																	tabIndex={-1}
																	disableRipple
																	disableTouchRipple
																	disableFocusRipple
																	inputProps={{ 'aria-labelledby': labelId }}
																/>
															</ListItemIcon>
															<ListItemText
																id={labelId}
																primary={
																	<Box className="type-text-block">
																		<Box className="label-name">{type?.name}</Box>
																	</Box>
																}
															/>
														</ListItem>
													) : (
														<></>
													)
												) : (
													<ListItem key={index} role={undefined} dense button onClick={checkHandleToggle(type?.id)}>
														<ListItemIcon>
															<Checkbox
																icon={<CheckBoxIcon />}
																checkedIcon={<CheckedBoxIcon />}
																edge="start"
																checked={checked.indexOf(type?.id) !== -1}
																tabIndex={-1}
																disableRipple
																disableTouchRipple
																disableFocusRipple
																inputProps={{ 'aria-labelledby': labelId }}
															/>
														</ListItemIcon>
														<ListItemText
															id={labelId}
															primary={
																<Box className="type-text-block">
																	<Box className="label-name">{type?.name}</Box>
																</Box>
															}
														/>
													</ListItem>
												);
											})}
										</List>
										<Typography className="check-heading">Extra Options</Typography>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: '15px',
											}}
										>
											<Box
												style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
												className="is-paid-checkbox"
											>
												<Checkbox
													icon={<CheckBoxIcon />}
													checkedIcon={<CheckedBoxIcon />}
													edge="start"
													checked={isPaid}
													tabIndex={-1}
													disableRipple
													disableTouchRipple
													disableFocusRipple
													inputProps={{ 'aria-labelledby': 'isPaid' }}
													onClick={() => setIsPaid(!isPaid)}
												/>
												<Box className="label-name">Paid Post</Box>
											</Box>
											{isPaid && (
												<>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															gap: '15px',
															flexWrap: 'wrap',
														}}
													>
														<Typography>Select Amount:</Typography>

														<TextField
															id="select-amount-id"
															variant="filled"
															type="number"
															value={amount}
															inputProps={{ min: 5, max: 100 }}
															onChange={handleAmountUpdate}
															error={amountError}
															onBlur={validateAmount}
															className="amount-selector"
															InputProps={{
																startAdornment: <InputAdornment position="start">$</InputAdornment>,
															}}
														/>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															gap: '15px',
															flexWrap: 'wrap',
														}}
													>
														<Typography>Preview Image:</Typography>
														<ThumbnailUpload setThumbnail={setThumbnail} token={token} />
														<Typography style={{ fontSize: '12px' }}>1080 * 566</Typography>
													</Box>
												</>
											)}
										</Box>
										{amountError && (
											<span className="editior-error-message">{t('community.amount_validation_error')}</span>
										)}
									</Box>
								</Grid>

								{postData ? (
									<Grid item xs={12} md={12} className="form-btn">
										<Button
											fullWidth
											variant="contained"
											type="submit"
											className="create-post-btn cancel-btn"
											onClick={cancelBtnClick}
											disabled={isLoading}
										>
											{t('button_text.cancel')}
										</Button>
										<Button
											disabled={isLoading}
											fullWidth
											variant="contained"
											type="submit"
											className="create-post-btn"
										>
											{t('community.edit_post')}
										</Button>
									</Grid>
								) : (
									<Grid item xs={12} md={12}>
										<Button
											fullWidth
											disabled={isLoading}
											variant="contained"
											type="submit"
											className="create-post-btn"
										>
											{t('button_text.create')}
										</Button>
									</Grid>
								)}
							</Grid>
						</form>
					)}
				</Formik>
			</Box>
		</Box>
	);
};
