import { useState, useEffect, useRef } from 'react';
import {
	Avatar,
	Box,
	Button,
	Grid,
	Typography,
	Popover,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Tooltip,
	Link,
} from '@material-ui/core';
import { ReactComponent as StaticsUpIcon } from 'src/assets/images/icons/statics-arrow-up.svg';
import { ReactComponent as StaticsDownIcon } from 'src/assets/images/icons/statics-arrow-down.svg';
import { ReactComponent as CommentIcon } from 'src/assets/images/icons/comment.svg';
import { ReactComponent as ReferralsIcon } from 'src/assets/images/icons/referrals.svg';
import { ReactComponent as SharesIcon } from 'src/assets/images/icons/shares.svg';
import { ReactComponent as ReactionsIcon } from 'src/assets/images/icons/reactions.svg';
// import { ReactComponent as DownIcon } from 'src/assets/images/icons/community/down-icon.svg';
import { ReactComponent as VerifiedIcon } from 'src/assets/images/icons/verified.svg';
import { ReactComponent as UnverifiedIcon } from 'src/assets/images/icons/Unverified User.svg';
import { ReactComponent as ReportIcon } from 'src/assets/images/icons/comment-report.svg';
import './community-post.scss';
import _ from 'lodash';
import { useNavigate, useParams, NavLink, useLocation } from 'react-router-dom';
import { DialogBox } from '../DialogBox';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Share } from '../Share';
import { setApiMessage, youTubeVideoCheck } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import Viewer from 'react-viewer';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { fetchFromStorage } from 'src/helpers/context';
import { Report } from '../Report';
import { Reactions } from '../Reactions';
import ReactPlayer from 'react-player';
import { parse } from 'query-string';
import { MultiMediaLightBox } from '../MultiMediaLightBox';
import PostComment from './Comment';
import { useTranslation } from 'react-i18next';
import PostSkeleton from '../Skeletons/PostSkeleton';

export const CommunityPost = ({
	isView,
	isJoin,
	postName,
	time,
	postedBy,
	communityPost,
	topVal,
	downVal,
	contentText,
	contentImg,
	comment,
	shares,
	reactions,
	referrals,
	updateCommunityPost,
	updatePostData,
	handleDeletePost,
	cancelBtnClick,
	data,
	isAvailable,
	isPaid,
	amount,
	purchaseDate,
	addVote,
	addLike,
	badges,
	user,
	postId,
	communityName,
	postProfile,
	activityType,
	userURI,
	editAccess,
	postUserId,
	allUsersList,
	communityID,
	isPostList,
	communityData,
	closeCommunity,
	postDetails,
	receiver_type,
	linkList,
	matchBtnCondition,
	reactionData,
	likedStatus,
	userPage,
	postVideo,
	postAudio,
	postDoc,
	fetchPostDetails,
	singleItem,
	verifyUser,
	isLoading,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const query = parse(location.search);
	const token = fetchFromStorage('authData')?.token;
	const [commentClick, setCommentClick] = useState(false);
	const [loadingPost, setLoadingPost] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElReact, setAnchorElReact] = useState(null);
	const [opens, setOpen] = useState(false);
	const [commentData, setCommentData] = useState([]);
	const [reportReasonList, setReportReasonList] = useState([]);
	const [hasMoreItems, setHasMoreItems] = useState(true);
	const [first, setFirst] = useState(1);
	// const [StaticsUp, setStaticsUp] = useState(false);
	// const [staticsDown, setStaticsDown] = useState(false);
	// const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [model, setModel] = useState();
	// const [reaction, setReaction] = useState(false);
	const userId = user?.user_id;
	const { id } = useParams();
	const [list] = useState(5);
	const [reactionList, setReactionList] = useState([]);
	const [recentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecord] = useState();
	const [tab, setTab] = useState('1');
	const [previousParentId, setPreviousParentPostId] = useState('');
	const [postCommentData, setPostCommentData] = useState([]);
	const [defaultIndex, setDefaultIndex] = useState(0);
	const [visible, setVisible] = useState(false);
	const postContainerRef = useRef(null);

	// const [youTubeVideo] = useState(['https://www.youtube.com/watch?v=tUVDMcd8OE8']);

	const open = Boolean(anchorEl);
	const ids = open ? 'simple-popover' : undefined;

	const openReact = Boolean(anchorElReact);
	const idReact = openReact ? 'simple-popover' : undefined;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const AddReactions = (event) => {
		setAnchorElReact(event.currentTarget);
	};
	const handleCloseReact = () => {
		setAnchorElReact(null);
	};

	const combatDialogHandler = (title) => {
		setOpen(true);
		setModel(title);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	// const handleStaticsup = () => {
	//   StaticsUp ? setStaticsUp(false) : setStaticsUp(true);
	// }
	// const handleStaticDown = () => {
	//   staticsDown ? setStaticsDown(false) : setStaticsDown(true);
	// }
	const scrooTop = () => {
		const anchor = (window.document || document).querySelector('#back-to-top-post');

		if (anchor) {
			anchor.scrollIntoView();
		}
	};

	const fetchReactionsLists = async (postId, likeId, cPage) => {
		var metaLikeId = likeId === 0 || likeId === '' || likeId === undefined ? '' : likeId;

		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsList + `${postId}`, {
				params: {
					meta_like_id: metaLikeId,
					page: cPage,
					limit: list,
				},
			});

			if (status === 200) {
				setReactionList(data?.reactionData);
				setTotalRecord(data?.totalRecord);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// const theme = createMuiTheme({
	//   overrides: {
	//     MuiTooltip: {
	//       tooltip: {
	//         color: "#ffffff",
	//         backgroundColor: "#438ac1"
	//       }
	//     }
	//   }
	// });
	// FETCH REPORT REASON LIST
	const fetchReportReasonList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReportReasonList);

			if (status === 200) {
				setReportReasonList(data?.getReasonList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH ALL COMMENT DATA
	const fetchCommentData = async (flag, level, pagenumber) => {
		// page=${first}&limit=5`
		var comment_post_id = location?.state?.previous_parent_id ? location?.state?.previous_parent_id : id;

		try {
			const { status, data } = await axiosInstance.get(
				URLS.getAllComments + comment_post_id,
				{
					params: {
						page: pagenumber && pagenumber !== '' ? pagenumber : first,
						limit: '10',
						...(level && level !== '' && { level: level }),
					},
				},
				// URLS.getAllComments + id
			);

			if (status === 200) {
				const hasMore = !(data?.post_list.length < 10);

				flag && flag !== '' && setFirst(first + 1);
				setHasMoreItems(hasMore);
				// setCommentData(data?.post_list);
				setPreviousParentPostId(data?.previous_parent_post_id);
				pagenumber && pagenumber !== ''
					? setCommentData(data?.post_list)
					: setCommentData([...commentData, ...data?.post_list]);
				// setCommentData(data?.post_list);
			}
		} catch (err) {
			// TODO: Add logging
		}
	};
	// FETCH NOTIFICATION CLICK COMMENT DATA
	const fetchNotificationCommentData = async () => {
		var highlightPostId = location?.state?.post_comment_id
			? location?.state?.post_comment_id
			: query?.highlight_post_id;
		var postId = location?.state?.post_id ? location?.state?.post_id : query?.main_post_id;

		try {
			// const { status, data } = await axiosInstance.get(
			//   URLS.getAllComments + `/${location?.state?.comment_id}`
			//   // URLS.getAllComments + `/${477}`
			// );
			const { status, data } = await axiosInstance.get(URLS.getAllComments + postId, {
				params: {
					page: 1,
					limit: 10,
					...(highlightPostId && { highlight_post_id: highlightPostId }),
				},
				// headers
			});

			if (status === 200) {
				setPostCommentData(data?.post_list);
			}
		} catch (err) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		location?.state?.post_comment_id && fetchNotificationCommentData();
		query?.highlight_post_id && fetchNotificationCommentData();
	}, []);
	useEffect(() => {
		const commentOpen = isPostList && data?.comments === 'yes';

		setCommentClick(!!commentOpen);
	}, [isPostList, data]);

	// var urlValue = location?.pathname?.split('/');
	const imageurlArray = [];

	contentImg &&
		contentImg.map((msg) => {
			const imageArr = {
				src: msg?.Item?.data,
				downloadUrl: msg?.Item?.data,
			};

			imageurlArray.push(imageArr);

			return msg;
		});
	// const videourlArray = [];
	// postVideo && postVideo.map((msg) => {
	//   const imageArr = {
	//     src: msg?.Item?.data,
	//     downloadUrl: msg?.Item?.data,
	//   };
	//   videourlArray.push(imageArr);
	//   return msg;
	// });
	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	// PAGINATION FOR ENDORSE USER LIST
	const handlePaginationButtonClicks = async (navDirection) => {
		if (navDirection === 'prev') {
			if (recentPage > 1) {
				setCurrentPage(recentPage - 1);
				fetchReactionsLists(data?.post_id, '', recentPage - 1);
			}
		} else {
			if (recentPage < Math.ceil(totalRecord / list)) {
				setCurrentPage(recentPage + 1);
				fetchReactionsLists(data?.post_id, '', recentPage + 1);
			}
		}
	};
	const handlePaginationClicks = async (event, page) => {
		if (recentPage !== page) {
			setCurrentPage(Number(page));
			fetchReactionsLists(data?.post_id, '', Number(page));
		}
	};
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
		const tabValue = newValue - 1;

		setCurrentPage(1);
		fetchReactionsLists(data?.post_id, tabValue, 1);
	};
	const renderMedia = (item) => {
		switch (item?.Item?.type) {
			case 'doc':
				return (
					<Box className="post-image-slider">
						<Box className="message-doc-view">
							<Link href={item?.Item?.data} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.Item?.data)}
							</Link>
						</Box>
					</Box>
				);
			case 'image':
				return (
					<Box className="post-image-slider">
						<Box className="content-img">
							<Avatar src={item?.Item?.data} />
							{/* <LightBox
                imageurl={imageurlArray}
                image={item?.Item?.data}
                otherUserId=""
              /> */}
						</Box>
					</Box>
				);
			case 'video':
				return (
					<Box className="post-image-slider">
						<Box className="post-video-slider">
							<ReactPlayer
								className="react-player"
								url={item?.Item?.data}
								playing={false}
								width="100%"
								height="100%"
								controls={true}
							/>
						</Box>
					</Box>
				);
			case 'audio':
				return (
					<Box className="post-image-slider">
						<ReactPlayer
							className="react-player audio-player"
							url={item?.Item?.data}
							playing={false}
							width="270px"
							height="30px"
							controls={true}
						/>
					</Box>
				);
			default:
				return (
					<Box className="post-image-slider">
						<Box className="message-doc-view">
							<Link href={item?.Item?.data} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.Item?.data)}
							</Link>
						</Box>
					</Box>
				);
		}
	};
	const renderMediaHtml = (allMediaList) => {
		switch (String(allMediaList?.length)) {
			case '1':
				return (
					<>
						{allMediaList &&
							allMediaList?.map((media, index) => {
								return (
									<Grid
										key={index + 'renderMediaHtmlCase1'}
										item
										xs={12}
										md={12}
										onClick={() => setDefaultIndex(index)}
									>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
					</>
				);
			case '2':
			case '4':
				return (
					<>
						{allMediaList &&
							allMediaList?.map((media, index) => {
								return (
									<Grid key={index + 'renderMediaHtmlCase4'} item xs={6} md={6} onClick={() => setDefaultIndex(index)}>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
					</>
				);
			case '3':
				return (
					<>
						{allMediaList &&
							allMediaList.map((media, index) => {
								return (
									<Grid key={index + 'renderMediaHtmlCase3'} item xs={4} md={4} onClick={() => setDefaultIndex(index)}>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
					</>
				);
			case '5':
				return (
					<>
						{allMediaList &&
							allMediaList?.slice(0, 2).map((media, index) => {
								return (
									<Grid key={index + 'renderMediaHtmlCase5'} item xs={6} md={6} onClick={() => setDefaultIndex(index)}>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
						{allMediaList &&
							allMediaList?.slice(2, 5).map((media, index) => {
								return (
									<Grid
										key={index + 'renderMediaHtmlCase5/2'}
										item
										xs={4}
										md={4}
										onClick={() => setDefaultIndex(index + 2)}
									>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
					</>
				);
			default:
				return (
					<>
						{allMediaList &&
							allMediaList?.slice(0, 2).map((media, index) => {
								return (
									<Grid
										key={index + 'renderMediaHtmlCaseDefault'}
										item
										xs={6}
										md={6}
										onClick={() => setDefaultIndex(index)}
									>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
						{allMediaList &&
							allMediaList?.slice(2, 5).map((media, index) => {
								return (
									<Grid
										key={index + 'renderMediaHtmlCaseDefault2'}
										item
										xs={4}
										md={4}
										onClick={() => setDefaultIndex(index + 2)}
									>
										<Box
											className="media-box"
											style={{
												boxShadow: index === 2 && allMediaList.length > 5 && 'inset 0 0 0 100vw #20202096',
											}}
										>
											{renderMedia(media)}
											{index === 2 && allMediaList.length > 5 && (
												<Typography className="media-count">+{allMediaList.length - 5}</Typography>
											)}
										</Box>
									</Grid>
								);
							})}
					</>
				);
		}
	};
	var allMediaList = data && data?.PostLists?.filter((item) => item?.Item?.type !== 'text');

	var sliderArray = [];

	allMediaList &&
		allMediaList.map((item, index) => {
			var obj = {
				data: item?.Item?.data,
				type: item?.Item?.type,
			};

			sliderArray[index] = obj;
		});

	const redirectToSignUp = () => {
		navigate('/');
	};

	const onShareClick = () => {
		combatDialogHandler('share');
	};

	const onReportClick = () => {
		combatDialogHandler('report');
	};

	const onUpVoteClick = () => {
		if (!token) {
			redirectToSignUp();

			return;
		}

		!closeCommunity && addVote(data, 'vote_up', userPage, singleItem);
	};

	const onDownVoteClick = () => {
		if (!token) {
			redirectToSignUp();

			return;
		}

		!closeCommunity && addVote(data, 'vote_down', userPage, singleItem);
	};

	const onCommentsClick = () => {
		if (!token) {
			redirectToSignUp();

			return;
		}

		isJoin
			? window.location.pathname.includes('/post') && setCommentClick(!commentClick)
			: window.location.pathname.includes('/post') && setApiMessage('error', 'please join community');
	};

	useEffect(() => {
		const checkNavigateInsideApplication = (e) => {
			if (window && e.currentTarget.href.includes(window.location.origin) && e.currentTarget.target !== '_blank') {
				e.stopPropagation();
				e.preventDefault();

				const url = new URL(e.currentTarget.href);

				navigate(url.pathname);
			}

			e.stopPropagation();
		};

		if (postContainerRef.current) {
			const links = postContainerRef.current.querySelectorAll('a');

			links.forEach((link) => {
				link.addEventListener('click', checkNavigateInsideApplication);
			});

			return () => {
				links.forEach((link) => {
					link.removeEventListener('click', checkNavigateInsideApplication);
				});
			};
		}
	}, [contentText]);

	return (
		<>
			{isLoading ? (
				<PostSkeleton />
			) : (
				<>
					<Box className="community-post">
						{/* POST HEADER */}
						<Box className="post-header">
							<Box className="header-left">
								{communityPost ? (
									<>
										<Box className="user-box">
											<>
												<Avatar
													src={postProfile}
													className="user-img"
													style={{ cursor: 'pointer' }}
													onClick={() => {
														setVisible(true);
													}}
												/>
												{postProfile !== '' && postProfile !== undefined && postProfile !== null && (
													<Viewer
														visible={visible}
														onClose={() => {
															setVisible(false);
														}}
														images={[
															{
																src: postProfile,
																title: 'cover image',
															},
														]}
														downloadable
														noFooter={true}
														drag={false}
														zoomable={false}
														rotatable={false}
														scalable={false}
														disableMouseZoom={true}
													/>
												)}
											</>
											<Box className="name-time">
												<Box className="user-name-verification-sec">
													<NavLink to={`/user/${userURI}`} className="user-name">
														{postedBy}
													</NavLink>
													<Box className={'verify-icon-container'}>
														{verifyUser ? <VerifiedIcon /> : <UnverifiedIcon />}
													</Box>
													{isPaid && (
														<Box>
															{isAvailable ? (
																<div className="paid-post-label">
																	<svg
																		width="14"
																		height="16"
																		viewBox="0 0 14 16"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M12.8158 7.58501H4.87001V4.53028C4.87001 3.35698 5.88566 2.38219 7.15442 2.37034C8.43601 2.35849 9.4837 3.32142 9.4837 4.50361V4.97768C9.4837 5.37174 9.82652 5.68877 10.2526 5.68877H11.2779C11.704 5.68877 12.0469 5.37174 12.0469 4.97768V4.50361C12.0469 2.01479 9.85215 -0.00885949 7.16083 2.91702e-05C4.46951 0.00891783 2.30685 2.05923 2.30685 4.54806V7.58501H1.5379C0.68885 7.58501 0 8.22203 0 9.0072V13.7478C0 14.533 0.68885 15.17 1.5379 15.17H12.8158C13.6649 15.17 14.3537 14.533 14.3537 13.7478V9.0072C14.3537 8.22203 13.6649 7.58501 12.8158 7.58501ZM8.45843 12.0886C8.45843 12.7434 7.88493 13.2738 7.17685 13.2738C6.46878 13.2738 5.89527 12.7434 5.89527 12.0886V10.6664C5.89527 10.0116 6.46878 9.48126 7.17685 9.48126C7.88493 9.48126 8.45843 10.0116 8.45843 10.6664V12.0886Z"
																			fill="#2A7EB6"
																		/>
																	</svg>
																	<Typography>{t('checkout.paid_post')}</Typography>
																</div>
															) : (
																<div className="paid-post-label">
																	<svg
																		width="15"
																		height="16"
																		viewBox="0 0 15 16"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M12.078 7.46828H11.367V5.33535C11.367 2.85286 9.34666 0.832504 6.86417 0.832504C4.38168 0.832504 2.36132 2.85286 2.36132 5.33535V7.46828H1.65035C0.86531 7.46828 0.228394 8.1052 0.228394 8.89024V14.578C0.228394 15.3631 0.86531 16 1.65035 16H12.078C12.863 16 13.5 15.3631 13.5 14.578V8.89024C13.5 8.1052 12.863 7.46828 12.078 7.46828ZM8.9971 7.46828H4.73124V5.33535C4.73124 4.15928 5.6881 3.20243 6.86417 3.20243C8.04025 3.20243 8.9971 4.15928 8.9971 5.33535V7.46828Z"
																			fill="#2A7EB6"
																		/>
																	</svg>
																	<Typography>{t('checkout.paid_post')}</Typography>
																</div>
															)}
														</Box>
													)}
												</Box>
												<Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
													<Typography className="post-time">{time}</Typography>

													{purchaseDate && (
														<>
															<Typography className="post-time">|</Typography>{' '}
															<Typography className="purchased-time">
																Purchased On {new Date(purchaseDate).toLocaleDateString()}
															</Typography>
														</>
													)}
												</Box>
											</Box>
											{/* <Typography
					  className="post-title"
					  onClick={() => navigate(`/post/${data?.id}`)}
					>
					  {postName}
					</Typography> */}

											{/* <Typography className="posted-by" onClick={() => navigate(`/user/${userURI}`)}>{postedBy}</Typography> */}
										</Box>
									</>
								) : (
									<>
										<Box className="user-box">
											<>
												<Avatar
													src={postProfile}
													className="user-img"
													style={{ cursor: 'pointer' }}
													onClick={() => {
														setVisible(true);
													}}
												/>
												{postProfile !== '' && postProfile !== undefined && postProfile !== null && (
													<Viewer
														visible={visible}
														onClose={() => {
															setVisible(false);
														}}
														images={[
															{
																src: postProfile,
																title: 'cover image',
															},
														]}
														downloadable
														noFooter={true}
														drag={false}
														zoomable={false}
														rotatable={false}
														scalable={false}
														disableMouseZoom={true}
													/>
												)}
											</>
											<Box className="name-time">
												<Box className="user-name-verification-sec">
													<NavLink
														to={
															receiver_type === 'organisation'
																? `/organisation-details/${communityID}`
																: `/user/${userURI}`
														}
														className="user-name"
													>
														{postedBy}
													</NavLink>
													<Box className={'verify-icon-container'}>
														{receiver_type !== 'organisation' && (verifyUser ? <VerifiedIcon /> : <UnverifiedIcon />)}
													</Box>
													{isPaid && (
														<Box>
															{isAvailable ? (
																<div className="paid-post-label">
																	<svg
																		width="14"
																		height="16"
																		viewBox="0 0 14 16"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M12.8158 7.58501H4.87001V4.53028C4.87001 3.35698 5.88566 2.38219 7.15442 2.37034C8.43601 2.35849 9.4837 3.32142 9.4837 4.50361V4.97768C9.4837 5.37174 9.82652 5.68877 10.2526 5.68877H11.2779C11.704 5.68877 12.0469 5.37174 12.0469 4.97768V4.50361C12.0469 2.01479 9.85215 -0.00885949 7.16083 2.91702e-05C4.46951 0.00891783 2.30685 2.05923 2.30685 4.54806V7.58501H1.5379C0.68885 7.58501 0 8.22203 0 9.0072V13.7478C0 14.533 0.68885 15.17 1.5379 15.17H12.8158C13.6649 15.17 14.3537 14.533 14.3537 13.7478V9.0072C14.3537 8.22203 13.6649 7.58501 12.8158 7.58501ZM8.45843 12.0886C8.45843 12.7434 7.88493 13.2738 7.17685 13.2738C6.46878 13.2738 5.89527 12.7434 5.89527 12.0886V10.6664C5.89527 10.0116 6.46878 9.48126 7.17685 9.48126C7.88493 9.48126 8.45843 10.0116 8.45843 10.6664V12.0886Z"
																			fill="#2A7EB6"
																		/>
																	</svg>
																	<Typography>{t('checkout.paid_post')}</Typography>
																</div>
															) : (
																<div className="paid-post-label">
																	<svg
																		width="15"
																		height="16"
																		viewBox="0 0 15 16"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M12.078 7.46828H11.367V5.33535C11.367 2.85286 9.34666 0.832504 6.86417 0.832504C4.38168 0.832504 2.36132 2.85286 2.36132 5.33535V7.46828H1.65035C0.86531 7.46828 0.228394 8.1052 0.228394 8.89024V14.578C0.228394 15.3631 0.86531 16 1.65035 16H12.078C12.863 16 13.5 15.3631 13.5 14.578V8.89024C13.5 8.1052 12.863 7.46828 12.078 7.46828ZM8.9971 7.46828H4.73124V5.33535C4.73124 4.15928 5.6881 3.20243 6.86417 3.20243C8.04025 3.20243 8.9971 4.15928 8.9971 5.33535V7.46828Z"
																			fill="#2A7EB6"
																		/>
																	</svg>
																	<Typography>{t('checkout.paid_post')}</Typography>
																</div>
															)}
														</Box>
													)}
												</Box>

												{/* <Typography
						  className="user-name"
						  onClick={() => {
							receiver_type === 'organisation'
							  ? navigate(`/organisation-details/${communityID}`)
							  : navigate(`/user/${userURI}`);
						  }}
						>
						  {postedBy}
						</Typography> */}

												<Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
													<Typography className="post-time">{time}</Typography>

													{purchaseDate && (
														<>
															<Typography className="post-time">|</Typography>{' '}
															<Typography className="purchased-time">
																Purchased On {new Date(purchaseDate).toLocaleDateString()}
															</Typography>
														</>
													)}
												</Box>
												{/* <Typography className="posted-by" onClick={() => navigate(`/user/${userURI}`)}>{postedBy}</Typography> */}
											</Box>
										</Box>
										<Typography className="activity-type">
											{receiver_type === 'post' ? '' : t('community.community')}
										</Typography>
										<NavLink
											to={receiver_type === 'organisation' ? `/user/${userURI}` : `/communities/${communityID}`}
											className="community-name"
										>
											{communityName}
										</NavLink>

										{/* <Typography
					  className="community-name"
					  onClick={() => {
						receiver_type === 'organisation' ? navigate(`/user/${userURI}`)
						  : navigate(`/communities/${communityID}`);
					  }}
					>
					  {communityName}
					</Typography> */}
									</>
								)}
							</Box>
							<Box className="header-right">
								<Box className="stastics-box">
									{/* STASTICS UP VALUE */}
									<Box display="flex" alignItems="center">
										<IconButton className={data?.vote_up_status === 'yes' ? 'fill-blue' : ''} onClick={onUpVoteClick}>
											<StaticsUpIcon />
										</IconButton>
										<Typography className="statics-val up-val">{topVal || 0}</Typography>
									</Box>

									{/* STASTICS DOWN VALUE */}
									<Box display="flex" alignItems="center">
										<IconButton
											className={data?.vote_down_status === 'yes' ? 'fill-red' : ''}
											onClick={onDownVoteClick}
										>
											<StaticsDownIcon className="statics-down-icon" />
										</IconButton>
										<Typography className="statics-val">{downVal || 0}</Typography>
									</Box>
								</Box>

								{/* <Box className="badges-box">
					  {_.times(badges, () => {
						return <Typography className="badge-avatar" />;
					  })}
					</Box> */}
								<Box
									className="badges-box"
									onClick={() => {
										combatDialogHandler(t('community.reactions'));
										data?.post_id && fetchReactionsLists(data?.post_id, '', recentPage);
									}}
								>
									{data?.MetaLikeLists?.map((item, index) => {
										return <Avatar src={item?.item_data} className="badge-avatar" />;
									})}
								</Box>
							</Box>
						</Box>

						{/* POST CONTENT */}
						<Box className="post-content">
							{communityPost ? (
								<NavLink to={`/post/${data?.id}`} className="post-title">
									{postName}
								</NavLink>
							) : (
								<NavLink to={`/post/${data?.id}`} className="post-title">
									{postName}
								</NavLink>
								// <Typography
								//   className="post-title"
								//   onClick={() => navigate(`/post/${data?.id}`)}
								// >
								//   {postName}
								// </Typography>
							)}
							{contentText ? (
								<Typography className="content-text" ref={postContainerRef}>
									{/* <Typography className="content-text">{contentText}</Typography> */}
									<base href={window.location.origin} />
									{
										// USER PROFILE TYPE ABOUT TEXT
										renderHTML(
											anchorme({
												input: contentText.toString().replace(/\n/g, ''),
												options: {
													attributes: {
														target: '_blank',
														class: 'detected',
													},
													protocol: 'https://',
												},
											}),
										)
									}
								</Typography>
							) : null}
							{linkList &&
								linkList?.map((item) => {
									var checkYTLink = youTubeVideoCheck(item);

									return (
										checkYTLink && (
											<Box style={{ margin: '10px', height: '250px' }}>
												<ReactPlayer
													className="react-player"
													url={item}
													playing={false}
													width="100%"
													height="100%"
													controls={true}
												/>
											</Box>
										)
									);
								})}
							<Grid
								container
								spacing={1}
								// onClick={() => {
								// 	combatDialogHandler('');
								// }}
							>
								{renderMediaHtml(allMediaList)}
							</Grid>
							{/* {contentImg && contentImg.length > 0 ? (
				<Box className="post-image-slider">
				  {contentImg && contentImg.map((item, index) => (
					<Box className="content-img">
					  <LightBox
						imageurl={imageurlArray}
						image={item?.Item?.data}
						otherUserId=""
					  />
					</Box>
				  ))}
				</Box>
			  ) : null} */}
							{/* {
				postVideo && postVideo.length > 0 ? (
				  <Box className='post-image-slider'>
					{
					  postVideo && postVideo.map((item, index) => {
						return (
						  <Box className='post-video-slider'>
							<ReactPlayer
							  className='react-player'
							  url={item?.Item?.data}
							  playing={false}
							  width='100%'
							  height='100%'
							  controls={true}
							/>
						  </Box>)
					  })}
				  </Box>
				) : null
			  } */}
							{/* {
				postAudio && postAudio.length > 0 ? (
				  <Box className='post-image-slider'>
					{
					  postAudio && postAudio.map((item, index) => {
						return (
						  <ReactPlayer
							className='react-player audio-player'
							url={item?.Item?.data}
							playing={false}
							width='270px'
							height='30px'
							controls={true}
						  />
						);
					  })}
				  </Box>
				) : null
			  } */}
							{/* {
				postDoc && postDoc.length > 0 ? (
				  <Box className='post-image-slider'>
					{
					  postDoc && postDoc.map((item, index) => {
						return (
						  <Box className="message-doc-view">
							<Link href={item?.Item?.data} target="_blank" className='doc-link'>
							  {renderSwitchForDocumentIcon(item?.Item?.data)}
							</Link>
						  </Box>
						)
					  })
					}
				  </Box>
				) : null
			  } */}
						</Box>
						<Box className="post-actions">
							<Grid container className="post-act-btns">
								{/* COMMENTS */}
								{data?.comments === 'yes' && (
									<Grid item>
										<Button
											disableElevation
											disableRipple
											disableFocusRipple
											disableTouchRipple
											className="act-btn"
											disabled={token && closeCommunity}
											startIcon={<CommentIcon />}
											component={window.location.pathname.includes('/post') ? Button : NavLink}
											to={window.location.pathname.includes('/post') ? null : `/post/${data?.id}`}
											onClick={onCommentsClick}
										>
											{t('community.comment')} ({comment || 0})
										</Button>
									</Grid>
								)}

								{/* SHARES */}
								<Grid item>
									<Button
										variant="contained"
										disableElevation
										disableRipple
										disableFocusRipple
										disableTouchRipple
										className="act-btn"
										disabled={token && closeCommunity}
										startIcon={<SharesIcon />}
										onClick={onShareClick}
									>
										{t('community.shares')} ({shares || 0})
									</Button>
								</Grid>
								{postDetails && postDetails ? (
									<Grid item>
										<Button
											variant="contained"
											disableElevation
											disableRipple
											disableFocusRipple
											disableTouchRipple
											className="act-btn"
											disabled={token && closeCommunity}
											startIcon={<ReportIcon />}
											onClick={onReportClick}
										>
											{t('report')}
										</Button>
									</Grid>
								) : (
									<></>
								)}
							</Grid>

							{editAccess || postUserId === userId || user?.isAdmin ? (
								<>
									<Popover
										className="admin-tools-popover"
										id={ids}
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}
									>
										<Box className="admin-tools">
											<List>
												{editAccess || user?.isAdmin ? (
													<ListItem>
														<ListItemText
															onClick={() => {
																updateCommunityPost(data, data?.sticky === 'yes' ? 'no' : 'yes');
																handleClose();
															}}
														>
															{data?.sticky === 'yes' ? t('community.remove_from_sp') : t('community.sticky_post')}
														</ListItemText>
													</ListItem>
												) : (
													<></>
												)}
												{receiver_type !== 'user' && (
													<ListItem>
														<ListItemText
															onClick={() => {
																handleClose();
																updatePostData(data);
																setTimeout(() => {
																	scrooTop();
																}, 600);
															}}
														>
															{t('community.edit_post')}
														</ListItemText>
													</ListItem>
												)}
												<ListItem
													onClick={() => {
														handleDeletePost(data);
														handleClose();
													}}
												>
													<ListItemText>{t('community.delete_post')}</ListItemText>
												</ListItem>
											</List>
										</Box>
									</Popover>
									{matchBtnCondition === 'no' ? (
										''
									) : (
										<Box className="admin-box">
											{/* {urlValue && urlValue[1] === 'news-feed' && ( */}
											{(editAccess || postUserId === userId || user?.isAdmin) && (
												<Button
													variant="contained"
													fullWidth
													disableElevation
													disableRipple
													disableTouchRipple
													disableFocusRipple
													className="admin-btn"
													disabled={closeCommunity || !user}
													onClick={(e) => {
														handleClick(e);
														cancelBtnClick();
													}}
													// endIcon={<DownIcon />}
												>
													{user?.isAdmin ? t('community.admin') : t('community.manage_post')}
												</Button>
											)}
										</Box>
									)}
								</>
							) : null}
							{isPaid && !isAvailable && (
								<Button
									variant="contained"
									fullWidth
									disableElevation
									disableRipple
									disableTouchRipple
									disableFocusRipple
									className="admin-btn"
									disabled={closeCommunity || !user}
									onClick={() => {
										navigate(`/checkout?post_id=${data?.post_id}`);
									}}
									style={{ marginTop: '10px' }}
									// endIcon={<DownIcon />}
								>
									{t('checkout.unlock_post_now')}
									{amount}
								</Button>
							)}
						</Box>
					</Box>
					{commentClick && !closeCommunity ? (
						<>
							<PostComment
								fetchPostDetails={fetchPostDetails}
								fetchNotificationCommentData={fetchNotificationCommentData}
								postId={data?.id}
								isView={isView}
								commentData={commentData}
								setCommentData={setCommentData}
								reportReasonList={reportReasonList}
								fetchReportReasonList={fetchReportReasonList}
								fetchCommentData={fetchCommentData}
								allUsersList={allUsersList}
								hasMoreItems={hasMoreItems}
								user={user}
								isJoin={
									!(
										fetchFromStorage('authData')?.user_id === communityData?.user_owner_id ||
										communityData?.join_status === 'accepted' ||
										data?.receiver_type === 'post' ||
										data?.receiver_type === 'user' ||
										data?.receiver_type === 'organisation'
									)
								}
								notificationCmt={location?.state?.comment_id}
								navigate={navigate}
								postCommentData={postCommentData}
								previousParentId={previousParentId}
							/>
						</>
					) : null}
					<DialogBox
						className={
							model === 'share'
								? 'share-dailog-box'
								: model === 'Reactions'
								? 'reaction-dialog-box'
								: model === ''
								? 'media-dialog-box'
								: ''
						}
						open={opens}
						handleClose={closeDialog}
						title={
							model === 'report' ? t('report') : model === t('community.reactions') ? t('community.reactions') : ''
						}
						content={
							model === 'share' ? (
								<Share
									fetchPostDetails={fetchPostDetails}
									fromWhere="post/"
									urlId={data?.id}
									landType="post"
									handleClose={closeDialog}
								/>
							) : model === 'report' ? (
								<Report
									handleClose={closeDialog}
									reportReasonList={reportReasonList}
									type="post"
									receiverId={data?.id}
								/>
							) : model === t('community.reactions') ? (
								<Reactions
									reactionList={reactionList}
									fetchReactionsLists={fetchReactionsLists}
									postId={data?.post_id}
									handlePaginationButtonClicks={handlePaginationButtonClicks}
									handlePaginationClicks={handlePaginationClicks}
									totalRecord={totalRecord}
									list={list}
									recentPage={recentPage}
									tabChangeHandler={tabChangeHandler}
									tab={tab}
								/>
							) : model === '' ? (
								<MultiMediaLightBox mediArray={sliderArray} defaultIndex={defaultIndex} />
							) : (
								<></>
							)
						}
					/>
				</>
			)}
		</>
	);
};
